/* You can add global styles to this file, and also import other style files */
$gutter: 1rem;

@import '../node_modules/primeng/resources/primeng.min.css';
@import '../node_modules/primeflex/primeflex.scss';
@import '../node_modules/primeicons/primeicons.css';
@import '../node_modules/prismjs/themes/prism-coy.css';
@import 'assets/demo/flags/flags.css';
@import 'assets/demo/documentation.scss';
@import '@fortawesome/fontawesome-free/css/all.css';
